import { useState, useEffect, createContext, useContext } from 'react';
import { createThemeConfig } from 'components/themes';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isHavingValue } from 'utils/objectUtils';
import { getAxiosBackground, internalGet } from 'apiClient/config/apiAxiosConfig';
import { useIsMounted } from 'modules/picasso-ui/form/useIsMounted';
import { AppLoadingState } from './component/AppLoadingState';
import { useTheme } from '@emotion/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FontLoader } from './scaffold/FontLoader';

export const AppContext = createContext({});

export const AppProvider = ({children, initial, loadAppEnabled}) => {

  const { app } = loadApp(initial, loadAppEnabled);

/*   if (app) {
    console.debug('app ' + app.key + ' ('+(initial ? 'pre': 'client')+') ');
  } */

  useEffect(()=>{
    if (!app) {
      return;
    }
    window.yoioContext=window.yoioContext||{}
    window.yoioContext.app=window.yoioContext.app||{}
    window.yoioContext.app.browserAuthDomain = app.browserAuthDomain
  },[app])

  if (!app) {
    return <AppLoadingState />
  }

  return (
    <AppContext.Provider value={app}>
      {children}
    </AppContext.Provider>
  )
}

/** 
const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: false
});
*/

export const MuiThemeFromApp = ({children}) => {
 
  const app = useContext(AppContext);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  if (!app) {
    return <></>;
  }
  
  let theme = app?.themeConfig ? createTheme(app.themeConfig) : createTheme()
  
  if (theme) {
    if (theme.typography) {
      theme.typography.h1 = {
        ...theme.typography.h1,
        [theme.breakpoints.down('md')]: {
          fontSize: '2.2rem',
        },
      
      };
    }
  }

  let fontsToLoad = null;
  try {
    fontsToLoad = theme.typography.fontFamily.split(',')
  } catch (error) {
    throw new Error(error)
  }

  fontsToLoad = fontsToLoad??[]
  if (app.themeFonts) {
    fontsToLoad = [...fontsToLoad, ...app.themeFonts]
  }

  return <>
    {theme && (
        <ThemeProvider theme={theme}>
          {fontsToLoad && <FontLoader fontFamilies={fontsToLoad} />}
          <CssBaseline />
          <ThemeCssProperties />
          {children}
        </ThemeProvider>
    )}
    {!theme &&
      children
    }
  </>;
}

const ThemeCssProperties = () => {

  const theme = useTheme()

  function escapeStyleProperty(value) {
    if (typeof value === 'number') {
      return value
    }
    if (!value){
      return ''
    }
    let sanitizedValue = value.replace(/;/g, '');
    return sanitizedValue
  }

  return (
    <Head>
      <style
          key="theme-vars"
          dangerouslySetInnerHTML={{
          __html: `
              :root {
                --palette-primary-main: ${escapeStyleProperty(theme.palette.primary.main)};
                --palette-primary-light: ${escapeStyleProperty(theme.palette.primary.light)};
                --palette-primary-background: ${escapeStyleProperty(theme.palette.primary.background)};
                --palette-secondary-main: ${escapeStyleProperty(theme.palette.secondary.main)};
                --palette-secondary-light: ${escapeStyleProperty(theme.palette.secondary.light)};
                --palette-text-primary: ${escapeStyleProperty(theme.palette.text.primary)};
                --palette-text-secondary: ${escapeStyleProperty(theme.palette.text.secondary)};
                --option-standard--border:1px solid #E5E7EB;
                --option-standard--borderColor:#E5E7EB;
                --option-standard--hover--borderColor:${escapeStyleProperty(theme.palette.secondary.main)};
                --option-standard--hover--backgroundColor:#F8FAFC;
                --option-selected--border:2px solid ${escapeStyleProperty(theme.palette.secondary.main)};
                --option-selected--backgroundColor:#F8FAFC;
                --breakpoints-xs:${escapeStyleProperty(theme.breakpoints.values.xs)};
                --breakpoints-sm:${escapeStyleProperty(theme.breakpoints.values.sm)};
                --breakpoints-md:${escapeStyleProperty(theme.breakpoints.values.md)};
                --breakpoints-lg:${escapeStyleProperty(theme.breakpoints.values.lg)};
                --breakpoints-xl:${escapeStyleProperty(theme.breakpoints.values.xl)};
              }
            `,
          }}
      >
      </style>
    </Head>
  )
}

export const useApp = () => {
  const app = useContext(AppContext)

  return { app }
}

/**
 * 
 * loads app.
 * 
 * If called from client side, loads via client api url.
 * uiHost param is not used and not required when called from client side.
 * 
 * If called from server side, loads via internal connection (node server to api server).
 * Must include param uiHost if you call it from server side.
 * 
 * @param {*} uiHost 
 *    e.g. ^
 *       - plinzip.localhost
 *       - plinzip.localhost:3000
 * @param {*} pathname 
 * @returns 
 */
export const determineApp = async (uiHost) => {
    //const start = Date.now()


    const handleResponse = (res)=>{
      const appData = res.data

      const { uiData, ...other } = appData

      const appDataEffective = {
        ...other,
        ...uiData,
        uiData,
      }

      //const end = Date.now()
      //const dur = end-start
      //console.log('app pre '+ dur + 'ms')

      return appDataEffective;
    }


    if (typeof window !== 'undefined') {
        //client side
        //ignores uihost because it does normal ui api call
        return getAxiosBackground().get('/apps/current/data').then(handleResponse)
    } else {
       //server side
      return internalGet('/apps/current/data', uiHost).then(handleResponse)
    }



/*     if (host && host.includes(':')) {
      host = host.split(':')[0];
    }

    return createAppSpecificInstanceToApi(host).get('/apps/current/data').then() */

}


export const loadApp = (initial, enabled) => {

  const [app, setApp] = useState(isHavingValue(initial) ? initial : null);
    
  //const [error, setError] = useState()

  const router = useRouter()

   const isMounted = useIsMounted()

    useEffect(() => {
      if (enabled === false) {
        return;
      }

      if (!initial) {
        if (typeof window !== 'undefined') {
          determineApp().then((updated)=> {
            if (!isMounted()) {
              return;
            }
            setApp(updated);
          })
/*           .catch(()=>{
            setError('loadAppError')
          }) */
        }
      }
    }, [global.window]);

    const logos = {
      v1: {
        app: {
          logoSrc: '/vsprio/logo-2-icon.png',
          favIconSrc: '/vsprio/logo-2-icon.png',
          logoWithTextSrc: '/vsprio/logo-2-text.png',
          logoWithTextWidth: '114px',
          logoWithTextHeight: '28px',
        },
        gradient: 'linear-gradient(to right, rgb(215 78 70), rgb(18 0 87))',
      },
      v2: {
        app: {
          logoSrc: '/vsprio/logo-2-icon.png',
          favIconSrc: '/vsprio/logo-2-icon.png',
          logoWithTextSrc: '/vsprio/logo-2-text-bold.png',
          logoWithTextWidth: '106px',
          logoWithTextHeight: '28px',
        }
      },
      v3: {
        app: {
          logoSrc: '/vsprio/logo-3-icon.png',
          favIconSrc: '/vsprio/logo-3-icon.png',
          logoWithTextSrc: '/vsprio/logo-3-text.png',
          logoWithTextWidth: '135px',
          logoWithTextHeight: '28px',
        }, 
        primaryMain: '#f9be5a',
      },
      'v3.2': {
        app: {
          logoSrc: '/vsprio/logo-3-icon.png',
          favIconSrc: '/vsprio/logo-3-icon.png',
          logoWithTextSrc: '/vsprio/logo-3-text.png',
          logoWithTextWidth: '135px',
          logoWithTextHeight: '28px',
        }, 
        primaryMain: '#fcb53d',
        gradient: 'linear-gradient(to right, #ffcd6b, #eda1b3)',
      },
      'v3.3': {
        app: {
          logoSrc: '/vsprio/logo-3-icon.png',
          favIconSrc: '/vsprio/logo-3-icon.png',
          logoWithTextSrc: '/vsprio/logo-3-text.png',
          logoWithTextWidth: '135px',
          logoWithTextHeight: '28px',
        }, 
        primaryMain: '#ffc562',
        buttonColor: '#000'
      },
      v4: {
        app: {
          logoSrc: '/vsprio/logo-4-icon.png',
          favIconSrc: '/vsprio/logo-4-icon.png',
          logoWithTextSrc: '/vsprio/logo-4-text.png',
          logoWithTextWidth: '129px',
          logoWithTextHeight: '28px',
        },
        primaryMain: '#feb75a',
      },
      v5: {
        app: {
          logoSrc: '/vsprio/logo-5-icon.png',
          favIconSrc: '/vsprio/logo-5-icon.png',
          logoWithTextSrc: '/vsprio/logo-5-text.png',
          logoWithTextWidth: '129px',
          logoWithTextHeight: '28px',
        },
        primaryMain: '#feb75a',
      },
      v6: {
        app: {
          logoSrc: '/vsprio/logo-5-icon.png',
          favIconSrc: '/vsprio/logo-5-icon.png',
          logoWithTextSrc: '/vsprio/logo-5-text.png',
          logoWithTextWidth: '129px',
          logoWithTextHeight: '28px',
        },
        primaryMain: '#425c8f',
      },
      'v7': {
        app: {
          logoSrc: '/vsprio/logo-3-icon.png',
          favIconSrc: '/vsprio/logo-3-icon.png',
          logoWithTextSrc: '/vsprio/logo-3-text.png',
          logoWithTextWidth: '135px',
          logoWithTextHeight: '28px',
        }, 
        primaryMain: '#feb75a',
        gradient: 'linear-gradient(to right, #ffcd6b, #eda1b3)',
      },
      'v8': {
        app: {
          logoSrc: '/vsprio/logo-8-icon.png',
          favIconSrc: '/vsprio/logo-8-icon.png',
          logoWithTextSrc: '/vsprio/logo-3-text.png',
          logoWithTextWidth: '135px',
          logoWithTextHeight: '28px',
        }, 
        primaryMain: '#ffd36e',
        gradient: 'linear-gradient(to right, #ffcd6b, #eda1b3)',
      },
      'v9': {
        app: {
          logoSrc: '/vsprio/logo-9-icon.png',
          favIconSrc: '/vsprio/logo-9-icon.png',
          logoWithTextSrc: '/vsprio/logo-9-text.png',
          logoWithTextWidth: '135px',
          logoWithTextHeight: '28px',
        }, 
      },
    }

    const logotest = router.query.logotest == 'true'
    const logoTestLogoEffective = logotest && router.query.logovar ? logos[router.query.logovar] : logos.v1;

    const appEffective = app?.key === 'plinzip' && logotest
        ? {
          ...app,
          ...logoTestLogoEffective?.app,
          logoWithTextHeight: '28px',
          uiData: {
            ...app?.uiData,
            themeConfig: {
              ...app?.uiData?.themeConfig,
              palette: {
                ...app?.uiData?.themeConfig.palette,
                gradient: logoTestLogoEffective?.gradient,
                primary: {
                  ...app?.uiData?.themeConfig?.palette?.primary,
                },
                text: {
                  primary: '#263657'
                }
              },
              button: {
                color: logoTestLogoEffective?.buttonColor
              }
            }
          },
        }
        : app

    if (logotest) {
      appEffective.uiData.themeConfig = appEffective.uiData.themeConfig || {}
      
      const primaryMain = router.query.primaryMain 
          ? '#'+router.query.primaryMain
          : logoTestLogoEffective?.primaryMain

      const secondaryMain = router.query.secondaryMain 
          ? '#'+router.query.secondaryMain
          : logoTestLogoEffective?.secondaryMain

      if (primaryMain) {
        appEffective.uiData.themeConfig.palette = appEffective.uiData.themeConfig.palette || {}
        appEffective.uiData.themeConfig.palette.primary = appEffective.uiData.themeConfig.palette.primary || {}
        appEffective.uiData.themeConfig.palette.primary.main = primaryMain
      }
      if (secondaryMain) {
        appEffective.uiData.themeConfig.palette = appEffective.uiData.themeConfig.palette || {}
        appEffective.uiData.themeConfig.palette.secondary = appEffective.uiData.themeConfig.palette.secondary || {}
        appEffective.uiData.themeConfig.palette.secondary.main = primaryMain
      }

    }

    if (appEffective?.useDefaultTheme) {
      appEffective.themeConfig = createThemeConfig(appEffective?.uiData?.themeConfig)
    }

    if (appEffective) {
      appEffective.themeFonts = appEffective?.uiData?.themeFonts
    }
    
    return {
        app: appEffective,
        //error
    }
  };